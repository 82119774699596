<template>
  <a v-if="state.showSkipLink" class="skip-link no-underline var-dark" href="#content">
    <span class="skip-link__text">{{ $t('skip-to-content') }}</span>
  </a>
  <header id="top" class="site-wrapper site-wrapper--header" tabindex="-1">
    <placeholder
      v-if="appState?.routeData"
      :key="`header-${state.key}`"
      name="jss-header"
      :rendering="appState.routeData"
    />
  </header>
  <visitor-identification />
  <div v-if="state.loading" class="loading-wrapper">
    <loading-spinner />
  </div>
  <layout v-else :route="appState.routeData" />
  <footer class="site-wrapper site-wrapper--footer">
    <placeholder
      v-if="appState?.routeData"
      :key="`footer-${state.key}`"
      name="jss-footer"
      :rendering="appState.routeData"
    />
    <back-to-top />
  </footer>
  <route-announcer
    :is-route-loading="state.loading"
    :title="appState?.routeData?.fields?.metaTitle?.value"
  />
</template>

<script setup>
import {
  inject,
  watch,
  onBeforeMount,
  reactive,
  watchEffect,
  defineAsyncComponent,
  computed,
} from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { isEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import { useServerHead } from '@unhead/vue';
import { dataFetcher } from './dataFetcher';
import { layoutServiceFactory } from './lib/layout-service-factory';
import { useRegisterDataLayerAction } from './data-layer/helpers/registerDataLayer';
import config from './temp/config';
import Layout from './Layout.vue';
import LoadingSpinner from './components/2_elements/LoadingSpinner/LoadingSpinner.vue';
import VisitorIdentification from './VisitorIdentification';
import RouteAnnouncer from './RouteAnnouncer.vue';

const BackToTop = defineAsyncComponent(() =>
  import(/* webpackChunkName: "BackToTop" */ './components/2_elements/BackToTop/BackToTop.vue')
);

const jssStore = inject('jssStore');
const registerDataLayerAction = useRegisterDataLayerAction();

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

const props = defineProps({
  route: {
    type: Object,
  },
});

const state = reactive({
  notFound: true,
  defaultLanguage: config.defaultLanguage,
  loading: true,
  key: '',
  showSkipLink: false,
});

const showSkipLink = () =>
  !!appState?.routeData?.placeholders['jss-header']?.find((c) => c.componentName === 'MainHeader');

// To take advantage of Vue's reactive data for tracking app state changes, we need
// to reference the same `state` object that the $jss store references in order for mutations to be observed.
// $jss is attached to the App instance via `SitecoreJssPlugin`.
const appState = jssStore.store.state;

// if the app state has routeData, we don't need to load it and don't need a loading screen
if (appState.routeData) {
  state.loading = false;
  state.showSkipLink = showSkipLink();
}

// route path from vue router - if route was resolved, it's not a 404
if (props.route !== null) {
  state.notFound = false;
}

// if we have an initial SSR state, and that state doesn't have a valid route data,
// then this is a 404 route.
if (!appState.routeData) {
  state.notFound = true;
}

// if we have initial context data, and that context data has a language defined, set the default language
// (this makes the language of content follow the Sitecore context language cookie)
// note that a route-based language (i.e. /de-DE) will override this default; this is for home.
if (appState?.sitecoreContext?.language) {
  state.defaultLanguage = appState.sitecoreContext.language;
}

const trackingApiOptions = {
  host: appState?.sitecoreContext?.venueOrigin,
  querystringParams: {
    sc_apikey: config.sitecoreApiKey,
  },
  fetcher: dataFetcher,
};

const reviewsBannerFields = appState?.routeData?.placeholders?.['jss-main'].find(
  (component) => component?.componentName === 'ReviewsBanner'
)?.fields;

const preconnectOrigins = [
  { href: 'https://consent.cookiebot.com' },
  reviewsBannerFields?.tripAdvisorId?.value && { href: 'https://www.tripadvisor.com' },
  reviewsBannerFields?.reputation?.value?.widgetId && {
    href: 'https://widgets.reputation.com',
    crossorigin: true,
  },
].map(
  (origin) =>
    origin && {
      ...origin,
      rel: 'preconnect',
    }
);

useServerHead({
  link: [
    {
      rel: 'dns-prefetch',
      href: 'https://sdk.woosmap.com',
    },
    ...preconnectOrigins,
  ],
});

// watch for a change in the 'route' prop
watch(
  () => props.route,
  (newRoute, oldRoute) => {
    // if the route contains a hash value, assume the URL is a named anchor/bookmark link, e.g. /page#anchorId.
    // in that scenario, we don't want to fetch new route data but instead allow default browser behavior.
    if (newRoute.hash !== '' && newRoute.path === oldRoute.path) {
      return;
    }
    // if in Sitecore editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      if (newRoute.path !== oldRoute.path) {
        window.location.assign(newRoute.path);
      }
      return;
    }
    // if the route contains book a space widget and a number parameter e.g. /book/4
    const routeWithBooking = appState.routeData.placeholders['jss-main'].some(
      (c) => c.componentName === 'BookSpaceWidget'
    );

    const bookingRouteWithParams = (route) =>
      routeWithBooking &&
      !!route.params?.sitecoreRoute &&
      ['2', '3', '4', '5', '6', '7', '8', '9', '10'].includes(
        route.params.sitecoreRoute[route.params.sitecoreRoute.length - 1]
      );

    if (bookingRouteWithParams(newRoute)) {
      // overwrite undefined state from BookASpace widget with necessery properties
      history.replaceState(
        {
          key: history.state?.key ?? null,
          back: oldRoute.path,
          current: `/${newRoute.params.sitecoreRoute.slice(0, -1).join('/')}`,
        },
        ''
      );

      if (newRoute.path === oldRoute.path) {
        window.location.href = `${window.location.origin}/${newRoute.params.sitecoreRoute
          .slice(0, -1)
          .join('/')}`;
      }
      return;
    }

    if (bookingRouteWithParams(oldRoute)) {
      updateRouteData(
        `${window.location.origin}/${oldRoute.params.sitecoreRoute.slice(0, -1).join('/')}`
      );
      return;
    }

    // if the route contains iorder and there are query params - don't reload the page
    const routeWithIOrder = appState.routeData.placeholders['jss-main'].some(
      (c) => c.componentName === 'IOrderMenu'
    );

    if (
      routeWithIOrder &&
      newRoute.path === oldRoute.path &&
      (newRoute.query.type || newRoute.query.section)
    ) {
      return;
    }

    updateRouteData(`${window.location.origin}${oldRoute.path}`);
  }
);

// watch for not found state changes, if the route is not found - reload the page completely
watchEffect(() => {
  if (state.notFound && !state.loading) {
    window.location.reload();
  }
});

onBeforeMount(() => {
  // if no existing routeData is present (from SSR), get Layout Service fetching the route data
  if (!appState.routeData) {
    updateRouteData();
  } else if (window?.Cookiebot?.hasResponse) {
    trackPageView();
  } else {
    window.addEventListener('CookiebotOnAccept', trackPageView);
  }
});

const getSitecoreRoutePath = () => {
  let sitecoreRoutePath = props.route.params.sitecoreRoute
    ? props.route.params.sitecoreRoute.join('/')
    : '/';
  if (!sitecoreRoutePath.startsWith('/')) {
    sitecoreRoutePath = `/${sitecoreRoutePath}`;
  }
  return sitecoreRoutePath;
};

/**
 * Loads route data from Sitecore Layout Service into appState.routeData
 */
const updateRouteData = (oldPath) => {
  const sitecoreRoutePath = getSitecoreRoutePath();

  const language =
    props.route.params.lang || appState.sitecoreContext.language || state.defaultLanguage;
  state.loading = true;

  const apiHost = appState?.sitecoreContext?.venueOrigin;
  const siteName = appState?.sitecoreContext?.site?.name;

  // instantiate layout service
  const layoutServiceInstance = layoutServiceFactory.create(apiHost, siteName);
  // get the route data for the new route
  layoutServiceInstance
    .fetchLayoutData(sitecoreRoutePath, language)
    .then((routeData) => {
      if (props.route?.path !== sitecoreRoutePath) return;
      if (routeData?.sitecore?.route) {
        // Update the JSS store instance with the fetched data.
        // This will signal the RouteHandler to update/re-render, as well as any components
        // that are referencing the JSS store instance in their `data` object.
        jssStore.store.setSitecoreData(routeData);
        state.notFound = false;
        state.key = appState?.routeData?.fields['Page Design']?.id;
        state.showSkipLink = showSkipLink();

        document.body.id = appState?.sitecoreContext?.brandName;
        document.body.classList.add(appState?.sitecoreContext?.theme);
        trackPageView();
        if (oldPath) registerAction(oldPath, routeData);
      } else {
        jssStore.store.setSitecoreData(routeData);
        state.notFound = true;
      }
      state.loading = false;
    })
    .catch((error) => console.warn(error));
};

/**
 * Data layer action send after changing the page
 */
const registerAction = (oldPath, newData) => {
  const title = newData?.sitecore?.route?.fields?.metaTitle?.value;
  const newPath = newData?.sitecore?.context?.canonicalUrl;

  const { sitecoreContext } = appState || {};
  const {
    brandName: pubBrandName = null,
    segment: pubSegmentName = null,
    venueId = '',
    venueName = '',
    routeName: pageCategory = '',
    venueOrigin = '',
    itemPath = '',
  } = sitecoreContext;

  const fullUrl = computed(() =>
    !isServer() ? window.location.href.split('?')[0] : venueOrigin + itemPath
  );

  if (title && newPath && oldPath) {
    registerDataLayerAction({
      event: 'spa_page_view',
      pageTitle: title,
      pageLocation: newPath,
      pageReferrer: oldPath,
      pubBrandName,
      pubSegmentName,
      pubName: `${venueName}-${venueId}`,
      pageCategory,
      fullUrl: fullUrl.value,
    });
  }
};

const trackPageView = () => {
  if (!window?.Cookiebot?.consent?.statistics) return;
  const pageId = appState?.routeData?.itemId;
  const url = getSitecoreRoutePath();

  trackingApi
    .trackEvent(
      [
        {
          pageId,
          url,
        },
      ],
      trackingApiOptions
    )
    .catch((error) => console.error(error));
};
</script>
