<template>
  <section
    v-if="promoItemsLength > 0 || $jss.sitecoreContext().pageEditing"
    ref="promoListSection"
    class="promo-list"
    :class="mainClasses"
  >
    <div
      class="container"
      :class="detectComponentVariantByBrandAndTheme(variantedComponents.PROMO_LIST)"
    >
      <div class="row">
        <div class="col promo-list__title-container" :data-btn-text="dataBtnTextAttribute">
          <div class="promo-list__text">
            <sc-text
              :field="rendering?.fields?.heading"
              class="promo-list__title"
              :class="titleClasses"
              tag="h2"
            />
          </div>
          <div v-if="hasViewMore" class="promo-list__view-all">
            <component
              v-bind="getLinkBindings(rendering.fields.viewMore)"
              :is="getLinkTag(rendering.fields.viewMore.value?.linktype)"
              :title="rendering.fields.viewMore.value.text"
              class="promo-list__link link"
            >
              {{ rendering?.fields?.viewMore.value.text }}
            </component>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col promo-list__description-container">
          <div class="promo-list__text">
            <sc-text :field="rendering?.fields?.text" class="promo-list__description" tag="p" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!$jss.sitecoreContext().pageEditing"
      class="promo-list__container"
      :tabindex="promoItemsLength > maxVisibleItemsCount ? 0 : null"
      :class="{ 'swiper--edge': isRightEdge }"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keydown="onKeyDown"
    >
      <swiper
        v-if="!isServer() && promoItemsLength > maxVisibleItemsCount"
        :modules="modules"
        :breakpoints="breakpoints"
        :enabled="true"
        :centered-slides-bounds="true"
        :resistance-ratio="0"
        :scrollbar="{ draggable: true }"
        :a11y="{
          slideLabelMessage: '{{index}} of {{slidesLength}}',
        }"
        @swiper="onSwiperInit"
        @snap-index-change="onSnapIndexChange"
      >
        <template v-for="(promoItem, index) in promoListItems" :key="index">
          <swiper-slide v-if="index < MAX_SLIDES_DEFINED">
            <promo-list-item v-bind="promoItem" :index="index"></promo-list-item>
          </swiper-slide>
        </template>
      </swiper>
      <div v-else class="promo-list__items">
        <template v-for="(promoItem, index) in promoListItems" :key="index">
          <promo-list-item
            v-if="index < MAX_SLIDES_DEFINED"
            v-bind="promoItem"
            :index="index"
            :single="promoListItems.length === 1"
          ></promo-list-item>
        </template>
      </div>
    </div>
    <div v-else class="promo-list__container promo-list__container--editor">
      <sc-placeholder name="promo-list-placeholder" :rendering="rendering" />
      <h2 v-if="promoItemsLength > MAX_SLIDES_DEFINED" class="error">
        Warning! Only the first {{ MAX_SLIDES_DEFINED }} items will be rendered!
      </h2>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onUnmounted, ref, computed, inject } from 'vue';
import { Text as ScText, Placeholder as ScPlaceholder } from '@sitecore-jss/sitecore-jss-vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import PromoListItem from './PromoListItem/PromoListItem.vue';

import 'swiper/css';
import 'swiper/css/scrollbar';
import { promoListDataLayer } from '../../data-layer/promoList';
import { getLinkTag, getLinkBindings } from '../../utils/linkTransformer';
import { useRegisterEcommerceDataLayerAction } from '../../data-layer/helpers/registerEcommerceDataLayer';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../utils/componentVariants';
import { normalizePx } from '../../utils/scalingUtils';

const detectComponentVariantByBrandAndTheme = useDetectVariantByBrandAndTheme();
const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName || null;

const MAX_SLIDES_DEFINED = 9;
const ARROW_LEFT = 'ArrowLeft';
const ARROW_RIGHT = 'ArrowRight';

const props = defineProps({
  rendering: {
    type: Object,
  },
  fields: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const promoListItems =
  props.rendering?.placeholders['promo-list-placeholder']?.filter(
    (item) => item.componentName === 'PromoListItem' && !!item.dataSource && item.fields
  ) ?? [];

const promoItemsLength = promoListItems.filter(
  (item) => item.componentName === 'PromoListItem'
).length;

const modules = [Scrollbar, A11y];

const breakpoints = {
  0: {
    slidesOffsetBefore: 26,
    slidesOffsetAfter: 26,
    slidesPerView: 1.7,
  },
  [normalizePx(768)]: {
    enabled: true,
    slidesOffsetBefore: 46,
    slidesOffsetAfter: 46,
    slidesPerView: 2.26,
  },
  [normalizePx(1024)]: {
    enabled: true,
    slidesPerView: 3,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
  },
};

const mmTablet = !isServer()
  ? window.matchMedia('(min-width: 768px) and (max-width: 1024px)')
  : { matches: false };
const mmDesktop = !isServer() ? window.matchMedia('(min-width: 1024px)') : { matches: false };
const isTablet = ref();
const isDesktop = ref();
const isMounted = ref(false);
const isRightEdge = ref(false);
const promoListSection = ref();
const swiperRef = ref(null);
const isActiveKeyboardNavigation = ref(false);
const hasViewMore = computed(() => props.rendering?.fields?.viewMore?.value?.href);
const isDark = computed(() => props.rendering?.params?.alternativeBackground === '1');
const dataBtnTextAttribute = computed(() =>
  hasViewMore.value ? props.rendering?.fields?.viewMore?.value?.text ?? '' : null
);

const mainClasses = computed(() => [
  hasViewMore.value && 'promo-list--with-view-more',
  isDark.value && 'var-dark',
  brandName === 'ChefBrewer' && 'bottom-green-border top-green-border',
  isMounted.value && 'promo-list--mounted',
]);
const titleClasses = computed(() => [brandName === 'Belhaven' ? 'font-xl' : 'font-l']);

const checkBreakpoint = () => {
  isTablet.value = mmTablet.matches;
  isDesktop.value = mmDesktop.matches;
};

const maxVisibleItemsCount = computed(() => {
  switch (true) {
    case isDesktop.value:
      return 3;
    case isTablet.value:
      return 2;
    default:
      return 1;
  }
});

const onSnapIndexChange = (swp) => {
  isRightEdge.value = swp.snapIndex === swp.slides.length - 1;
};

const registerEcommerceDataLayerAction = useRegisterEcommerceDataLayerAction();

const registerVisiblePromoItems = () => {
  if (promoListItems.length) {
    registerEcommerceDataLayerAction({
      event: promoListDataLayer.categories.VIEW_PROMOTION,
      ecommerce: {
        items: promoListItems.map(({ fields }, index) => ({
          promotion_id: fields?.title?.value,
          promotion_name: fields?.title?.value,
          creative_name: fields?.text?.value,
          creative_slot: index + 1,
        })),
      },
    });
  }
};

const handleVisibleInView = () => {
  if (
    promoListSection?.value &&
    promoListSection.value.getBoundingClientRect().top < window.innerHeight / 2
  ) {
    registerVisiblePromoItems();
    document.removeEventListener('scroll', handleVisibleInView);
  }
};

const onSwiperInit = (e) => {
  swiperRef.value = e;
};

const onFocusIn = () => {
  isActiveKeyboardNavigation.value = true;
};

const onFocusOut = () => {
  isActiveKeyboardNavigation.value = false;
};

const onKeyDown = (e) => {
  if (!isActiveKeyboardNavigation.value) {
    return;
  }

  switch (e.code) {
    case ARROW_LEFT:
      swiperRef.value?.slidePrev();
      break;
    case ARROW_RIGHT:
      swiperRef.value?.slideNext();
      break;
    default:
      break;
  }
};

onMounted(() => {
  checkBreakpoint();
  isMounted.value = true;
  mmTablet.addEventListener('change', checkBreakpoint);
  mmDesktop.addEventListener('change', checkBreakpoint);
  document.addEventListener('scroll', handleVisibleInView);
});

onUnmounted(() => {
  document.removeEventListener('scroll', handleVisibleInView);
});
</script>
<style lang="scss">
@import './scss/PromoList.scss';
</style>
